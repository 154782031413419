;globalThis["_sentryRewritesTunnelPath"] = "/monitoring-tunnel";globalThis["SENTRY_RELEASE"] = {"id":"2a3c0fd0cc437c8a7b57ee3ac4cb2027d06961bd"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import { IS_LOCAL } from './src/utils/const'

const isSentryLogEnabled = !IS_LOCAL

Sentry.init({
  dsn: 'https://699d6044057685bc48c1e7d130ad0bce@o4507266158297088.ingest.de.sentry.io/4507270724714576',
  enabled: isSentryLogEnabled,
  // enabled: process.env.NODE_ENV === 'production',
  // Replay may only be enabled for the client-side
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [/.*\.learnwise\..*/], // Anything for our domains
      networkRequestHeaders: ['X-Clerk-Auth-Status'],
      mask: ['cl-formFieldInput__password'],
    }),
    Sentry.httpClientIntegration(),
    Sentry.breadcrumbsIntegration({
      console: true, // Enable console integration to capture logs as breadcrumbs
    }),
    Sentry.browserTracingIntegration(),
  ],
  tracePropagationTargets: ['localhost', /^https:\/\/api\.chat.*/],

  tracesSampleRate: 1.0,
  // Capture Replay for 2% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.02,
  replaysOnErrorSampleRate: 1.0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  attachStacktrace: true,
  normalizeDepth: 10,
  // ignoreErrors: ['API error! status: 401, message: JWT token has expired'],

  beforeSend: function (event, hint) {
    const exception: any = hint.originalException

    // if (exception.name === 'APIError') {
    //   event.fingerprint = [
    //     "{{ default }}",
    //     String(exception.name),
    //   ];
    // }
    // For API errors, create a custom fingerprint that groups by transaction and error message (excluding dynamic args/kwargs)
    if (exception?.name === 'APIError') {
      const message = exception.message || ''
      const baseMessage = message.split('with args')[0].trim() // Get message part before args/kwargs
      event.fingerprint = [event.transaction || '{{ default }}', 'API error', baseMessage]
      console.log('event.fingerprint', event.fingerprint)
    }
    // Handle object not found errors by removing UUIDs
    if (exception?.message?.includes('Object') && exception?.message?.includes('was not found')) {
      const message = exception.message || ''
      const sanitizedMessage = message.replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi, '<id>')
      event.fingerprint = [event.transaction || '{{ default }}', 'API error', sanitizedMessage]
      console.log('event.fingerprint', event.fingerprint)
    }
    if (exception?.message?.includes('JWT token has expired')) {
      return null
    }
    return event
  },
})
